import isNil from 'lodash/isNil'
import { computed, ref } from 'vue'
import { useRoute, onBeforeRouteUpdate } from 'vue-router'
import { useQuery, useMutation } from '@vue/apollo-composable'
import { CREATE_FORM_PROGRESSION } from '../../graphql/mutations/CREATE_FORM_PROGRESSION'
import { GET_FORM_PROGRESSION } from '../../graphql/queries/GET_FORM_PROGRESSION'
import { UPDATE_FORM_PROGRESSION } from '../../graphql/mutations/UPDATE_FORM_PROGRESSION'
import logError from '../../helpers/logError'
import { FormStep } from '../useFormStep'
import getFormattedFormSteps from '../../helpers/getFormattedFormSteps'
import { WatchQueryFetchPolicy } from '@apollo/client'

export interface FormAnswer {
  id: string
  answer: string
  stepId: FormStep['id']
}
export interface FormSchema {
  id: string
  schema: FormStep[]
}
export interface FormProgression {
  id: string
  formAnswers: FormAnswer[]
  formSchema: FormSchema
  moduleSlug: string
  nextStepId: string | null
  patientId: string | null
  practitionerId: string | null
  practitionerName: string | null
  expireAt: Date | null
  isCompleted: boolean | null
}

export default function useFormProgression(
  fetchPolicy?: WatchQueryFetchPolicy,
) {
  const id = ref(useRoute().params.formProgressionId as string)

  onBeforeRouteUpdate((to, from) => {
    if (to.params.formProgressionId !== from.params.formProgressionId) {
      id.value = to.params.formProgressionId as string
    }
  })

  const {
    result,
    error: formProgressionError,
    loading: formProgressionLoading,
    onResult: onFetchFormProgressionDone,
    onError: onFetchFormProgressionError,
    refetch: refetchFormProgression,
  } = useQuery<{ formProgression: FormProgression }>(
    GET_FORM_PROGRESSION,
    {
      id,
    },
    { enabled: !!id.value, fetchPolicy },
  )

  const formProgression = computed(() => result.value?.formProgression ?? null)
  const formSteps = computed(() => getFormattedFormSteps(formProgression.value))
  const currentStepId = computed(
    () => formProgression.value?.nextStepId ?? null,
  )

  onFetchFormProgressionDone(({ data }) => {
    const expirationDate = data?.formProgression?.expireAt

    if (
      !data?.formProgression?.isCompleted &&
      expirationDate &&
      new Date() > new Date(expirationDate)
    ) {
      logError(
        new Error(
          `The FormProgression ${data.formProgression.id} has expired.`,
        ),
      )
    }
  })

  onFetchFormProgressionError((error) => {
    logError(error)
  })

  const {
    mutate: createFormProgression,
    error: createFormProgressionError,
    loading: createFormProgressionLoading,
    onDone: onCreateFormProgressionDone,
    onError: onCreateFormProgressionError,
  } = useMutation(CREATE_FORM_PROGRESSION)

  onCreateFormProgressionError((error) => {
    logError(error)
  })

  const {
    mutate: update,
    error: updateFormProgressionError,
    loading: updateFormProgressionLoading,
    onDone: onUpdateFormProgressionDone,
    onError: onUpdateFormProgressionError,
  } = useMutation(UPDATE_FORM_PROGRESSION)

  const updateFormProgression = async (nextStepId: FormStep['id']) => {
    const nextStep = formSteps.value.find(({ id }) => id === nextStepId)

    if (
      formProgression.value &&
      ((currentStepId.value !== nextStepId && isNil(nextStep?.answer)) ||
        nextStep?.considerFormAsCompleted)
    ) {
      await update({
        id: id.value,
        nextStepId,
      })
    }
  }

  onUpdateFormProgressionError((error) => {
    logError(error)
  })

  return {
    createFormProgression,
    createFormProgressionError,
    createFormProgressionLoading,
    onCreateFormProgressionDone,
    onCreateFormProgressionError,
    formProgression,
    formProgressionError,
    formProgressionLoading,
    formSteps,
    currentStepId,
    onFetchFormProgressionDone,
    onFetchFormProgressionError,
    refetchFormProgression,
    updateFormProgressionError,
    updateFormProgressionLoading,
    updateFormProgression,
    onUpdateFormProgressionDone,
    onUpdateFormProgressionError,
  }
}
