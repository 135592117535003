import { gql } from 'graphql-tag'

export const GET_FORM_PROGRESSION = gql`
  query GET_FORM_PROGRESSION($id: ID) {
    formProgression(id: $id) {
      id
      formAnswers {
        id
        answer
        stepId
      }
      formSchema {
        id
        schema {
          id
          type
          order
          layout
          inStepper
          showStepper
          locales
          optional
          properties {
            action {
              mutation
              query
              args
              key
              onSuccess {
                goTo
                redirectTo {
                  name
                  params
                  replace
                  type
                  url
                }
              }
            }
            actions
            label
            labels
            list
            listProperties {
              order
              value
              label
            }
            name
            order
            pattern
            patternInstruction
            placeholder
            properties {
              action {
                mutation
                query
                args
                key
                onSuccess {
                  goTo
                  redirectTo {
                    name
                    params
                    replace
                    type
                    url
                  }
                }
              }
              actions
              label
              labels
              list
              name
              order
              pattern
              patternInstruction
              placeholder
              score
              type
              value
            }
            score
            type
            value
          }
          skipIf {
            value
            stepId
            nextStepId
            previousStepId
            rule {
              args
              name
            }
          }
          init {
            mutation
            query
            args
            key
            onSuccess {
              goTo
              redirectTo {
                name
                params
                replace
                type
                url
              }
            }
          }
        }
      }
      moduleSlug
      nextStepId
      patientId
      practitionerId
      practitionerName
      isCompleted
      expireAt
    }
  }
`
